import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import fetch from 'cross-fetch';
import { snackbarRef } from '../snackbar';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import Router from 'next/router';

export type { ResultOf } from '@graphql-typed-document-node/core';

const wordEngineVersion = `0-15`;

export const wordEngineEndpoint = (path: `/${string}`) =>
    `api/wordengine/api/hub/v${wordEngineVersion}` + path;

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
            snackbarRef.enqueueSnackbar(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                { variant: 'error' }
            );
            if (message.includes('jwt')) {
                Router.push('/login');
            }
        });
    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        snackbarRef.enqueueSnackbar(`[Network error]: ${networkError}`, { variant: 'error' });
    }
});

const batchLink = new HttpLink({
    uri: () => wordEngineEndpoint('/graphql'),
    credentials: 'include',
    fetch
});

const { link, useApolloNetworkStatus: networkStatus } = createNetworkStatusNotifier();

export const useApolloNetworkStatus = networkStatus;

const client = new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: {
        mutate: {
            errorPolicy: 'all'
        },
        query:
            typeof window === 'undefined'
                ? {
                      // disable cache serverside
                      fetchPolicy: 'no-cache',
                      errorPolicy: 'all'
                  }
                : undefined
    },
    link: from([link, errorLink, batchLink])
});

export default client;
