import { IToken, UserRole } from '@mws/wordengine-graph';
import Link from 'next/link';
import { useState, useEffect, useContext } from 'react';
import {
    faUsers,
    faCogs,
    faUserShield,
    faQuestion,
    faFileCode,
    faDesktop
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Get, removeCookie } from '../lib/utils/utils';
import Router from 'next/router';
import { getCookie } from '../lib/utils/utils';
import jwtDecode from 'jwt-decode';
import { useApolloNetworkStatus } from '../lib/services/apolloClient';
import LinearProgress from '@mui/material/LinearProgress';
import { GROUP_EDITOR } from 'src/lib/constants/pageUrls';
import { useUserRoles } from '../lib/services/userInfo';
import { googleLogout } from '@react-oauth/google';
import NamespaceToggler from './global-settings/NamespaceToggler';
import { GlobalSettingsContext } from './global-settings/context';
import LocaleToggler from './global-settings/LocaleToggler';

interface HeaderProps {
    loading: boolean;
}

const Header = ({ loading }: HeaderProps) => {
    const { state } = useContext(GlobalSettingsContext);

    const { namespaceColor } = state;

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [userToken, setUserToken] = useState<IToken>(null);
    const userRoles = useUserRoles();
    const networkStatus = useApolloNetworkStatus();

    const openMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        setUserToken(jwtDecode(getCookie('access_token')));

        document.getElementById('page-wrap').addEventListener('mouseup', () => {
            if (isMenuOpen) {
                setIsMenuOpen(false);
            }
        });
    }, []);

    const handleLogout = async () => {
        googleLogout();
        removeCookie('access_token');
        await Get(`api/login/logout`);
        Router.push('/login');
    };

    return (
        <header>
            <div
                id="mega-menu-dropdown"
                style={{ backgroundColor: `${namespaceColor}` }}
                className="navbar-item has-dropdown is-mega">
                <div className="navbar-item">
                    <img src="/logo.png" />
                </div>
                <div className="navbar-link" onClick={openMenu}>
                    Services
                </div>
                <div
                    id="mainMenuDropdown"
                    className="navbar-dropdown"
                    style={isMenuOpen ? { display: 'block' } : { display: 'none' }}>
                    <div className="container is-fluid">
                        <div className="columns link-container">
                            <div className="column">
                                <h1 className="title is-6 is-mega-menu-title">
                                    <FontAwesomeIcon icon={faUsers} /> Accounts & Users
                                </h1>
                                <Link
                                    href={{
                                        pathname: '/accounts',
                                        query: { namespace: state.namespace }
                                    }}>
                                    <a className="navbar-item" onClick={closeMenu}>
                                        <div className="navbar-content">
                                            <p>
                                                <small className="has-text-info">
                                                    Search Accounts
                                                </small>
                                            </p>
                                            <p>Search for accounts using email or account ID</p>
                                        </div>
                                    </a>
                                </Link>
                                <Link
                                    href={{
                                        pathname: '/users',
                                        query: { namespace: state.namespace }
                                    }}>
                                    <a className="navbar-item" onClick={closeMenu}>
                                        <div className="navbar-content">
                                            <p>
                                                <small className="has-text-info">
                                                    Search Users
                                                </small>
                                            </p>
                                            <p>Search for an account user using GUID</p>
                                        </div>
                                    </a>
                                </Link>
                                <Link
                                    href={{
                                        pathname: '/school-districts',
                                        query: { namespace: state.namespace }
                                    }}>
                                    <a className="navbar-item" onClick={closeMenu}>
                                        <div className="navbar-content">
                                            <p>
                                                <small className="has-text-info">
                                                    School Districts
                                                </small>
                                            </p>
                                            <p>Manage school districts</p>
                                        </div>
                                    </a>
                                </Link>
                            </div>
                            {!userRoles.every((role) => role === UserRole.CUSTOMER_SERVICE) && (
                                <>
                                    <div className="column">
                                        <h1 className="title is-6 is-mega-menu-title">
                                            <FontAwesomeIcon icon={faCogs} /> WordEngine
                                        </h1>
                                        <Link
                                            href={{
                                                pathname: `${GROUP_EDITOR}`,
                                                query: { namespace: state.namespace }
                                            }}>
                                            <a className="navbar-item" onClick={closeMenu}>
                                                <div className="navbar-content">
                                                    <p>
                                                        <small className="has-text-info">
                                                            Group Editor
                                                        </small>
                                                    </p>
                                                    <p>Build Word Groups</p>
                                                </div>
                                            </a>
                                        </Link>
                                        <Link
                                            href={{
                                                pathname: '/group-uploader',
                                                query: { namespace: state.namespace }
                                            }}>
                                            <a className="navbar-item" onClick={closeMenu}>
                                                <div className="navbar-content">
                                                    <p>
                                                        <small className="has-text-info">
                                                            Group Batch Uploader
                                                        </small>
                                                    </p>
                                                    <p>Upload a batch of words for a group</p>
                                                </div>
                                            </a>
                                        </Link>
                                        <Link
                                            href={{
                                                pathname: '/speech-synthesis',
                                                query: { namespace: state.namespace }
                                            }}>
                                            <a className="navbar-item" onClick={closeMenu}>
                                                <div className="nabar-content">
                                                    <p>
                                                        <small className="has-text-info">
                                                            Speech Synthesis
                                                        </small>
                                                    </p>
                                                    <p>Generate speech from uploaded Script file</p>
                                                </div>
                                            </a>
                                        </Link>
                                        <Link
                                            href={{
                                                pathname: '/audio-uploader',
                                                query: { namespace: state.namespace }
                                            }}>
                                            <a className="navbar-item" onClick={closeMenu}>
                                                <div className="nabar-content">
                                                    <p>
                                                        <small className="has-text-info">
                                                            Audio Uploader
                                                        </small>
                                                    </p>
                                                    <p>Upload Audio files to the WordEngine</p>
                                                </div>
                                            </a>
                                        </Link>
                                        <Link
                                            href={{
                                                pathname: '/sentence-batch-uploader',
                                                query: { namespace: state.namespace }
                                            }}>
                                            <a className="navbar-item" onClick={closeMenu}>
                                                <div className="navbar-content">
                                                    <p>
                                                        <small className="has-text-info">
                                                            Sentence Batch Upload
                                                        </small>
                                                    </p>
                                                    <p>Upload a batch of sentences in csv format</p>
                                                </div>
                                            </a>
                                        </Link>
                                        <Link
                                            href={{
                                                pathname: '/sentence-editor',
                                                query: { namespace: state.namespace }
                                            }}>
                                            <a className="navbar-item" onClick={closeMenu}>
                                                <div className="navbar-content">
                                                    <p>
                                                        <small className="has-text-info">
                                                            Sentence Editor
                                                        </small>
                                                    </p>
                                                    <p>Create and Edit Sentences</p>
                                                </div>
                                            </a>
                                        </Link>
                                    </div>
                                    <div className="column">
                                        <h1 className="title is-6 is-mega-menu-title">
                                            <FontAwesomeIcon icon={faCogs} /> Pluto Toolset
                                        </h1>
                                        <Link
                                            href={{
                                                pathname: '/pluto/sounds',
                                                query: { namespace: state.namespace }
                                            }}>
                                            <a className="navbar-item" onClick={closeMenu}>
                                                <div className="navbar-content">
                                                    <p>
                                                        <small className="has-text-info">
                                                            Sounds Controller
                                                        </small>
                                                    </p>
                                                    <p>Sound assets manager</p>
                                                </div>
                                            </a>
                                        </Link>

                                        <Link
                                            href={{
                                                pathname: '/pluto/games',
                                                query: { namespace: state.namespace }
                                            }}>
                                            <a className="navbar-item" onClick={closeMenu}>
                                                <div className="navbar-content">
                                                    <p>
                                                        <small className="has-text-info">
                                                            Games manager
                                                        </small>
                                                    </p>
                                                    <p>Game configurations</p>
                                                </div>
                                            </a>
                                        </Link>

                                        <Link
                                            href={{
                                                pathname: '/pluto/curriculum',
                                                query: { namespace: state.namespace }
                                            }}>
                                            <a className="navbar-item" onClick={closeMenu}>
                                                <div className="navbar-content">
                                                    <p>
                                                        <small className="has-text-info">
                                                            Curriculum sequence
                                                        </small>
                                                    </p>
                                                    <p>Game levels manager</p>
                                                </div>
                                            </a>
                                        </Link>

                                        <Link
                                            href={{
                                                pathname: '/pluto/quests',
                                                query: { namespace: state.namespace }
                                            }}>
                                            <a className="navbar-item" onClick={closeMenu}>
                                                <div className="nabar-content">
                                                    <p>
                                                        <small className="has-text-info">
                                                            Quest manager
                                                        </small>
                                                    </p>
                                                    <p>Quest editor tool</p>
                                                </div>
                                            </a>
                                        </Link>
                                    </div>
                                    <div className="column">
                                        <h1 className="title is-6 is-mega-menu-title">
                                            <FontAwesomeIcon icon={faUserShield} /> Staff
                                        </h1>
                                        <Link
                                            href={{
                                                pathname: '/staff',
                                                query: { namespace: state.namespace }
                                            }}>
                                            <a className="navbar-item" onClick={closeMenu}>
                                                <div className="navbar-content">
                                                    <p>
                                                        <small className="has-text-info">
                                                            Manage Staff accounts
                                                        </small>
                                                    </p>
                                                    <p>Manage CRM users and create new accounts</p>
                                                </div>
                                            </a>
                                        </Link>
                                    </div>
                                    <div className="column">
                                        <h1 className="title is-6 is-mega-menu-title">
                                            <FontAwesomeIcon icon={faQuestion} /> Other
                                        </h1>
                                        <Link
                                            href={{
                                                pathname: '/',
                                                query: { namespace: state.namespace }
                                            }}>
                                            <a className="navbar-item" onClick={closeMenu}>
                                                <div className="navbar-content">
                                                    <p>
                                                        <small className="has-text-info">
                                                            Dashboard
                                                        </small>
                                                    </p>
                                                    <p>Overview</p>
                                                </div>
                                            </a>
                                        </Link>
                                    </div>

                                    <div className="column">
                                        <h1 className="title is-6 is-mega-menu-title">
                                            <FontAwesomeIcon icon={faFileCode} /> API Documentation
                                        </h1>
                                        <Link
                                            href={`https://app-service-staging.mrswordsmith.com/api/v3-1/docs/`}>
                                            <a className="navbar-item" onClick={closeMenu}>
                                                <div className="navbar-content">
                                                    <p>
                                                        <small className="has-text-info">
                                                            Backend
                                                        </small>
                                                    </p>
                                                    <p>Authentication Platform</p>
                                                </div>
                                            </a>
                                        </Link>
                                    </div>

                                    <div className="column">
                                        <h1 className="title is-6 is-mega-menu-title">
                                            <FontAwesomeIcon icon={faDesktop} /> Web Services
                                        </h1>
                                        <Link href="/web-services/qr-content-manager">
                                            <a className="navbar-item" onClick={closeMenu}>
                                                <div className="navbar-content">
                                                    <p>
                                                        <small className="has-text-info">
                                                            QR Content Manager
                                                        </small>
                                                    </p>
                                                    <p>Manage the QR code content</p>
                                                </div>
                                            </a>
                                        </Link>
                                    </div>
                                </>
                            )}
                        </div>
                        <hr className="navbar-divider"></hr>
                    </div>
                </div>

                <div className="navbar-end">
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}>
                        <LocaleToggler />
                        <NamespaceToggler />
                        Logged in as&nbsp; <b>{userToken?.u || userToken?.sub}</b>
                        {userToken?.p.length > 0 && (
                            <>,&nbsp;({userToken?.p.map((p) => p).join(', ')})</>
                        )}
                        &nbsp;
                        <a className="button is-light" onClick={handleLogout}>
                            Log out
                        </a>
                    </div>
                </div>
            </div>
            <div style={{ height: '4px', backgroundColor: '#f5f5f5' }}>
                {(networkStatus.numPendingMutations + networkStatus.numPendingQueries > 0 ||
                    loading) && <LinearProgress sx={{ width: '100%' }} />}
            </div>

            <style jsx>{`
                #mega-menu-dropdown {
                    z-index: 100;
                }

                .link-container {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
                    grid-gap: 1rem;
                }
            `}</style>
        </header>
    );
};

export default Header;
