import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FunctionComponent, useContext } from 'react';
import { ChangeNamespace, Namespace, GlobalSettingsContext } from './context';
import { useRouter } from 'next/router';

const NamespaceToggler: FunctionComponent = () => {
    const router = useRouter();

    const { state, dispatch } = useContext(GlobalSettingsContext);
    const { namespace } = state;

    return (
        <FormControl sx={{ m: 1, minWidth: 200, maxWidth: 200 }} size="small">
            <InputLabel>Namespace</InputLabel>
            <Select
                value={namespace}
                label="Namespace"
                onChange={(event) => {
                    const newNamespace = event.target.value as Namespace;
                    router.query = { ...router.query, namespace: newNamespace };
                    router
                        .replace(router)
                        .then(() => dispatch(ChangeNamespace(event.target.value as Namespace)));
                }}>
                {Object.values(Namespace).map((name) => (
                    <MenuItem key={name} value={name}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default NamespaceToggler;
