import { useRouter } from 'next/router';
import { createContext, useReducer, Dispatch } from 'react';
import { ValueOf } from 'ts-essentials';

export enum Namespace {
    mrswordsmith = 'mrswordsmith',
    mcgrawhill = 'mcgrawhill'
}

export enum Locale {
    UK = 'UK',
    US = 'US'
}

export const NamespaceColor = {
    [Namespace.mrswordsmith]: '#9fcee5',
    [Namespace.mcgrawhill]: '#d18089'
} as const;

enum ActionType {
    CHANGE_NAMESPACE,
    CHANGE_LOCALE
}

interface State {
    namespace: Namespace;
    namespaceColor: ValueOf<typeof NamespaceColor>;
    locale: Locale;
}

interface IGlobalSettingsContext {
    state: State;
    dispatch: Dispatch<IChangeNamespace | IChangeLocale>;
}

interface IChangeNamespace {
    type: ActionType.CHANGE_NAMESPACE;
    payload: Namespace;
}

interface IChangeLocale {
    type: ActionType.CHANGE_LOCALE;
    payload: Locale;
}

const GlobalSettingsContext = createContext<IGlobalSettingsContext>({
    state: {
        namespace: Namespace.mrswordsmith,
        namespaceColor: NamespaceColor[Namespace.mrswordsmith],
        locale: Locale.UK
    },
    dispatch: () => null
});

const ChangeNamespace = (value: Namespace): IChangeNamespace => ({
    type: ActionType.CHANGE_NAMESPACE,
    payload: value
});

const ChangeLocale = (value: Locale): IChangeLocale => ({
    type: ActionType.CHANGE_LOCALE,
    payload: value
});

const reducer = (state: State, action: IChangeNamespace | IChangeLocale): State => {
    switch (action.type) {
        case ActionType.CHANGE_NAMESPACE:
            return {
                ...state,
                namespace: action.payload,
                namespaceColor: NamespaceColor[action.payload]
            };
        case ActionType.CHANGE_LOCALE:
            return {
                ...state,
                locale: action.payload
            };
        default:
            return state;
    }
};

const GlobalSettingsProvider = ({ children }: { children: JSX.Element }) => {
    const router = useRouter();
    let namespace = router.query.namespace as string;
    let locale = router.query.locale as string;

    if (!namespace && !(namespace in Namespace)) {
        namespace = Namespace.mrswordsmith;
    }

    if (!locale && !(locale in Locale)) {
        locale = Locale.UK;
    }

    const initialState: State = {
        namespace: Namespace[namespace],
        namespaceColor: NamespaceColor[Namespace[namespace]],
        locale: Locale[locale]
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <GlobalSettingsContext.Provider value={{ state, dispatch }}>
            {children}
        </GlobalSettingsContext.Provider>
    );
};

export { GlobalSettingsContext, GlobalSettingsProvider, ChangeNamespace, ChangeLocale };
